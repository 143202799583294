import { authService } from 'spec';

// Avoid using arrow functions here because of problems in functional context. (https://stackoverflow.com/questions/12995153/apply-not-working-as-expected)
// Please declare auth object concise as possible.
function defaultCheckAccess(claimNames) {
  return this.claims && claimNames.some((cn) => !!+this.claims[cn]);
}

function checkNormalEditAccess(claimNames) {
  return this.claims && claimNames.some((cn) => +this.claims[cn] === 1);
}

function checkProtectedEditAccess(claimNames) {
  return this.claims && claimNames.some((cn) => +this.claims[cn] === 2);
}

function checkNormalOrProtectedEditAccess(claimNames) {
  return (
    this.claims &&
    claimNames.some((cn) => +this.claims[cn] === 1 || +this.claims[cn] === 2)
  );
}

function checkVersionAccess() {
  return this.claims && !this.hasVersion;
}

function defaultCheckRolePermissions(claimNames) {
  return this.claims && this.claims?.role === claimNames[0];
}

// function defaultSampleCheckAccess(claimNames) {
//   return this.claims && claimNames.some((cn) => !+this.claims[cn]);
// }

export async function GetAuthObject() {
  const claims = await authService.getClaims();
  const hasVersion = await authService.isVersionValid();

  return {
    /* ********* below permissions will append to tailing of the desired permissions *********** */

    // At Least 1 claim be true --> AL1
    // Register --> R
    // Edit --> E
    // Normal Edit --> NE
    // Protected Edit --> PE
    // Register and Edit --> RE
    // Delete --> D
    // Print Information --> PI

    /* ***************************************************************************************** */
    BankAccountsList: defaultCheckAccess.call({ claims }, ['bo.d', 'bo.cou']),
    BankBookD: defaultCheckAccess.call({ claims }, ['bo.d']),
    BankBookRE: defaultCheckAccess.call({ claims }, ['bo.cou']),
    BankTransactionD: defaultCheckAccess.call({ claims }, ['bt.d']),
    BankTransactionRE: defaultCheckAccess.call({ claims }, ['bt.cou']),
    BranchD: defaultCheckAccess.call({ claims }, ['ag.d']),
    BranchesList: defaultCheckAccess.call({ claims }, ['ag.l']),
    BranchRE: defaultCheckAccess.call({ claims }, ['ag.cou']),
    CashTransactionD: defaultCheckAccess.call({ claims }, ['tc.d']),
    CashTransactionRE: defaultCheckAccess.call({ claims }, ['tc.cou']),
    ChequeD: defaultCheckAccess.call({ claims }, ['ch.d']),
    ChequeRE: defaultCheckAccess.call({ claims }, ['ch.cou']),
    ChequesList: defaultCheckAccess.call({ claims }, ['ch.d', 'ch.cou']),
    CompanyD: defaultCheckAccess.call({ claims }, ['com.d']),
    CompanyNE: checkNormalEditAccess.call({ claims }, ['com.u']),
    CompanyNEorPE: checkNormalOrProtectedEditAccess.call({ claims }, ['com.u']),
    CompanyOrReceiverE: defaultCheckAccess.call({ claims }, ['com.u', 'rec.u']),
    CompanyOrReceiverR: defaultCheckAccess.call({ claims }, ['com.c', 'rec.c']),
    CompanyPE: checkProtectedEditAccess.call({ claims }, ['com.u']),
    CompanyR: defaultCheckAccess.call({ claims }, ['com.c']),
    CurrencyCalculationD: defaultCheckAccess.call({ claims }, ['tyc.d']),
    CurrencyCalculationRE: defaultCheckAccess.call({ claims }, ['tyc.cou']),
    CurrencyManagementD: defaultCheckAccess.call({ claims }, ['mc.d']),
    CurrencyManagementRE: defaultCheckAccess.call({ claims }, ['mc.cou']),
    CurrencyManagementList: defaultCheckAccess.call({ claims }, [
      'mc.cou',
      'mc.d'
    ]),
    CustomersList: defaultCheckAccess.call({ claims }, ['per.l']),
    CustomersListAL1: defaultCheckAccess.call({ claims }, [
      'afp.d',
      'afp.p.i',
      'afp.cou',
      'com.d',
      'com.u',
      'per.l',
      'per.d',
      'per.u'
    ]),
    DayOperation: defaultCheckAccess.call({ claims }, ['d.o.l']),
    DraftDocumentsList: defaultCheckAccess.call({ claims }, ['drd.l']),
    IncomingDraftD: defaultCheckAccess.call({ claims }, ['dra.d']),
    IncomingDraftRE: defaultCheckAccess.call({ claims }, ['dra.cou']),
    IncreaseCustomerCredit: defaultCheckAccess.call({ claims }, ['icc']),
    InterbankTransactionD: defaultCheckAccess.call({ claims }, ['t2b.d']),
    InterbankTransactionRE: defaultCheckAccess.call({ claims }, ['t2b.cou']),
    IsAdmin: defaultCheckAccess.call({ claims }, ['a.m.u']),
    MiscellaneousDraftR: defaultCheckAccess.call({ claims }, ['drm.c']),
    OutgoingDraftD: defaultCheckAccess.call({ claims }, ['drr.d']),
    OutgoingDraftRE: defaultCheckAccess.call({ claims }, ['drr.cou']),
    PendingDraftsList: defaultCheckAccess.call({ claims }, ['p.d.l']),
    PersonAccountD: defaultCheckAccess.call({ claims }, ['afp.d']),
    PersonAccountPI: defaultCheckAccess.call({ claims }, ['afp.p.i']),
    PersonAccountRE: defaultCheckAccess.call({ claims }, ['afp.cou']),
    PersonAccountREorPIorD: defaultCheckAccess.call({ claims }, [
      'afp.d',
      'afp.p.i',
      'afp.cou'
    ]),
    PersonD: defaultCheckAccess.call({ claims }, ['per.d']),
    PersonNE: checkNormalEditAccess.call({ claims }, ['per.u']),
    PersonPE: checkProtectedEditAccess.call({ claims }, ['per.u']),
    PersonNEorPE: checkNormalOrProtectedEditAccess.call({ claims }, ['per.u']),
    PersonOrReceiverE: defaultCheckAccess.call({ claims }, ['per.u', 'rec.u']),
    PersonOrReceiverR: defaultCheckAccess.call({ claims }, ['per.c', 'rec.c']),
    PersonR: defaultCheckAccess.call({ claims }, ['per.c']),
    ProfitAndLossStatement: defaultCheckAccess.call({ claims }, ['pals.r']),
    ReceiverD: defaultCheckAccess.call({ claims }, ['rec.d']),
    ReceiverForCustomerRE: defaultCheckAccess.call({ claims }, ['r2p.cou']),
    ReceiverForCustomerD: defaultCheckAccess.call({ claims }, ['r2p.d']),
    ReceiverNE: checkNormalEditAccess.call({ claims }, ['rec.u']),
    ReceiverNEorPE: checkNormalOrProtectedEditAccess.call({ claims }, [
      'rec.u'
    ]),
    ReceiverPE: checkProtectedEditAccess.call({ claims }, ['rec.u']),
    ReceiverR: defaultCheckAccess.call({ claims }, ['rec.c']),
    ReceiversList: defaultCheckAccess.call({ claims }, ['rec.l']),
    SystemAccountsD: defaultCheckAccess.call({ claims }, ['afs.d']),
    SystemAccountsRE: defaultCheckAccess.call({ claims }, ['afs.cou']),
    SystemAccountsList: defaultCheckAccess.call({ claims }, [
      'afs.d',
      'afs.cou'
    ]),
    TradeCurrencyD: defaultCheckAccess.call({ claims }, ['bsc.d']),
    TradeCurrencyRE: defaultCheckAccess.call({ claims }, ['bsc.cou']),
    TransferBetweenAccountsD: defaultCheckAccess.call(
      {
        claims
      },
      ['t2a.d']
    ),
    TransferBetweenAccountsRE: defaultCheckAccess.call(
      {
        claims
      },
      ['t2a.cou']
    ),
    UploadExcelFile: defaultCheckAccess.call({ claims }, ['bue']),
    LimitDateTransaction: defaultCheckAccess.call({ claims }, ['l.d.t']),
    Ticketing: defaultCheckAccess.call({ claims }, ['tic']),
    WhatsappAccess: defaultCheckAccess.call({ claims }, ['w.a']),
    AccountUpdating: defaultCheckAccess.call({ claims }, ['a.u']),
    GetManagers: defaultCheckAccess.call({ claims }, ['m.c']),
    HideAccountAccess: defaultCheckAccess.call({ claims }, ['h.a.a']),
    CreateVirtualBankAccount: defaultCheckAccess.call({ claims }, ['c.vba']),
    AnonymousUser: checkVersionAccess.call({ claims, hasVersion }),
    AfghanOfficial: defaultCheckRolePermissions.call({ claims }, [
      'afghanofficial'
    ])
  };
}

export default GetAuthObject;
